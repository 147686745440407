body {
  margin: 0;
  background: #fff;
}

html {
  font-family: verdana;
  font-size: 1.25rem;
}

svg:focus {
  outline: none !important;
}

path:focus {
  outline: none !important;
}

svg {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  /* left: -100px;
  right: -100px; */
  margin: -100px;
  height: 100vh;
}

.darkMode {
  background: black;
  color: white !important;
}

.darkMode .css-yafthl-MuiSlider-markLabel {
  color: white !important;
}

.darkMode .css-1eoe787-MuiSlider-markLabel {
  color: white !important;
}

.darkMode .css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
  border: 1px solid white;
  background: white;
}

.Button {
  background-color: darkblue;
  color: white;
  padding: 10px;
  border-radius: 10px;
  font-size: 20pt;
  margin: 10px;
  cursor: pointer;
  width: 200px;
  text-align: center;
  border: 1px solid darkblue;
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.Button:hover {
  background-color: #ff3333;
  color: white;
}

.Dropdown {
  background-color: darkblue;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid darkblue;
}

.Container {
  width: 80%;
  height: 0;
  position: relative;
}

.Overlay {
  background-color: grey;
  text-align: center;
  z-index: 9;
  margin: 0px;
  position: absolute;
  top: 200px;
  width: 100%;
  height: 50px;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  display: flex;
}

.Graph {
  filter: blur(2px);
  position: 'absolute';
}